import PropTypes from "prop-types";
import React from "react";

import i18nContext from "../components/i18n/i18n.context";
import Layout from "../components/layout/Layout";
import Page from "../components/page/Page";
import Routing from "../components/routing/Routing";
import SEO from "../components/seo/Seo";

function RoutingPage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "fr" }}>
      <Layout>
        <SEO
          keywords={["Jawg", "JawgMaps", "API", "Routing", "Navigation"]}
          location={location}
          metaDescription="Avec Jawg Routing, calculez la distance entre un point A et un point B par la route, en voiture ou à pied."
          title="Routing"
        />
        <Page>
          <Routing />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

RoutingPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default RoutingPage;
